/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useState } from "react";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

const pathname = window.location.href;

let grid;


if (pathname.includes("/product/log-cather")) {
  grid = (
    <Grid container spacing={3} item xs={12} lg={8} mx="auto">
        <MKTypography variant="h3" mb={3}>
          App Tracker
        </MKTypography>
        <MKTypography variant="body2">
        AppTracker daha ətraflı qeydlərə daxil ola bilər, çünki o, hadisənin baş verdiyi yerdə işləyir.
        AppTracker ilə izlənilə bilən hadisələrə nümunələr:
        <ul style={{marginLeft: "5%", marginTop: "2%"}}>
          <li>
            İstifadəçinin hansı proqramı nə qədər işlətdiyini izləmək — məsələn, Excel faylında kaç saat iş görülmüşdür?
          </li>
          <li>
            YouTube və ya sosial media saytlarında “aktiv” olaraq nə qədər vaxt sərf olunub?
          </li>
          <li>
            Gizli veb fəaliyyətlərinin monitorinqi  — Firewall / Proksi serverdən istifadə etmədən və ya VPN üzərindən edilen veb fəaliyyətlərin monitorinqi, kompüterə qoşulmuş mobil telefon vasitəsilə İnternetdə edilen əməliyyatların izlənməsi
          </li>
          <li>
            TOR, VPN, Periscope kimi proqramların istifadəsinə nəzarət
          </li>
          <li>
            Kompüterdə heç nə etmədən nə qədər vaxt sərf olundu?
          </li>
        </ul>
      </MKTypography>
    </Grid>
  )
} else if (pathname.includes("/product/dataskope-database")) {
  grid = (
    <>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
        Əsas Xüsusiyyətlər və Faydalar
      </MKTypography>
      <MKTypography variant="body2">
          DATASKOPE  məhsulu, verilənlər bazası sistemlərinizdə baş verən hadisələri aşkarlayır, təsnif edir, 
          həyəcan siqnalları yaradır və xəbər verir. Həssas məlumatlara kimin daxil olduğu və hansı 
          fasilələrlə hansı tədbirlərin görüldüyü barədə məlumatlara asanlıqla daxil olmağa imkan verən ,
          əvvəlcədən təyin edilmiş siqnalizasiya qaydalarıyla səlahiyyətli şəxslərin hadisələr haqqında məlumatlandırılmasını 
          təmin edən bir verilənlər bazası audit məhsuludur.
          DATASKOPE ilə aşağıdakı yoxlamaları həyata keçirə və hesabat verə bilərsiniz:
          <ul style={{marginLeft: "5%", marginTop: "2%"}}>
            <li>
            Login (istifadəçi adı və parolu)- Verilənlər bazasına uğurlu və uğursuz girişləri izləməyə imkan verir.
            </li>
            <li>
            Məlumat məzmunu mübadiləsi – SELECT, UPDATE, DELETE, EXEC, və s. əmrləri izləməyə imkan verir.
            </li>
            <li>
            Daxilolmaların izlənilməsi – Orjinal (həssas) məlumatlara kimin daxil olduğunu izləməyinizə imkan verir.
            </li>
            <li>
            Verilənlər bazası administratorlarının yoxlanılması– İstifadəçinin yaradılması, silinməsi və s. əməliyyatları izləyir və hərəkət etməyə imkan verir.
            </li>
            <li>
            Təsnif edilmiş nəzarət – İstifadəçi, verilənlər bazası, cədvəl, əmr əsasında əməliyyatlara kimin baxa biləcəyinə qərar verməyə imkan verir.
            </li>
            <li>
            Hesabat verilməsi – ISO27001, COBIT, PCI, SOX kimi standartlara uyğun hesabatlar əldə etməyə imkan verir.
            </li>
          </ul>
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      ETİBARLI VƏ TƏHLÜKƏSİZ
      </MKTypography>
      <MKTypography variant="body2">
          <ul style={{marginLeft: "5%", marginTop: "2%"}}>
            <li>
            "root" şifrəsi sizindir.&quot;Heç kimlə&quot;paylaşmaq lazım deyil.
            </li>
            <li>
            Hər sətirdə “hash” məlumatları var.
            </li>
            <li>
            Məlumatlarınız heç kimlə paylaşılmayacaq!
            </li>
            <li>
            Mənbə kodu bizdə mövcuddur və onu görə bilərsiz.
            </li>
          </ul>
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      HƏSSAS MƏLUMATA HÖRMƏTLƏ YANAŞAN
      </MKTypography>
      <MKTypography variant="body2">
          <ul style={{marginLeft: "5%", marginTop: "2%"}}>
            <li>
            Səlahiyyətə əsaslanan girişlə, kimin hansı məlumatı görə biləcəyinə qərar verirsiniz.
            </li>
            <li>
            Şəxsiyyət vəsiqəsi, kredit kartı və e -poçt kimi şəxsi məlumatlar avtomatik olaraq gizlədilir.
            </li>
            <li>
            RegEx istifadə edərək öz həssas məlumat formatınızı təyin edə bilərsiniz.
            </li>
            <li>
            Admin fəaliyyətini də qeyd edə bilər və hansı şəxsin məlumatlarına daxil olunma prosesini bələ izləyə bilərsiniz.
            </li>
          </ul>
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      SÜRƏTLİ VƏ GENİŞLƏNƏ BİLƏN İNFRASTRUKTUR
      </MKTypography>
      <MKTypography variant="body2">
          <ul style={{marginLeft: "5%", marginTop: "2%"}}>
            <li>
            Sürətli axtarış etmək üçün əlavə SIEM məhsulları almaq lazım deyil.
            </li>
            <li>
            2TB  yaddaşa kimi bir məhdudiyyət yoxdur. Diskinizin yaddaş həcmi nə qədər yer tutursa bir o qədər daha çox məlumatı canlı yaddaşda saxlaya bilərsiniz.
            </li>
            <li>
            İstənilən sayda sorğunu eyni anda işlədə və saniyələr ərzində nəticələr əldə edə bilərsiniz.
            </li>
          </ul>
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      İDARƏ OLUNA BİLƏN
      </MKTypography>
      <MKTypography variant="body2">
          <ul style={{marginLeft: "5%", marginTop: "2%"}}>
            <li>
            Yaddaşın dolmasına səbəb olacaq bəzi məlumatların dublikatlarını yaddaşdan çıxara bilərsiniz.
            </li>
            <li>
            Dataskope Installation Manager ilə  agent quraşdırma işini uzaqdan edə bilərsiniz.
            </li>
            <li>
            Agentlər C ++ dilində hazırlandığı üçün, Java versiyasının yenilənməməsi vəziyyəti ilə qarşılaşmazsınız.
            </li>
            <li>
            Quraşdırıldıqdan sonra agentləri idarə etmək üçün "admin" səlahiyyəti daşımağa ehtiyac yoxdur.
            </li>
          </ul>
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      DAHA SƏRFƏLİ
      </MKTypography>
      <MKTypography variant="body2">
          <ul style={{marginLeft: "5%", marginTop: "2%"}}>
            <li>
            400 verilənlər bazası sisteminin qeydlərini aparmaq üçün 100 ədəd loq toplama serverinə ehtiyac yoxdur!
            </li>
            <li>
            İstifadəçi dəyişikliklərini aşkar etmək üçün əlavə modullar almaq lazım deyil!
            </li>
            <li>
            Serveriniz sürətli  olduğu üçün lazımsız ödənişlər etmirsiniz!
            </li>
            <li>
            EPS yoxdur. Verilənlər bazası və server əsaslı lisenziya seçimləri var!
            </li>
          </ul>
    </MKTypography>
    </Grid>
    </>
  )
}

function Information(props) {
  const {otherSections} = props;
  

  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
        {grid}
      </Container>
    </MKBox>
  );
}

export default Information;
