/*eslint-disable */
/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useState } from "react";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  

  return (
      <MKBox component="section" py={3} mt={3}>
        <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="body2">
        2003-cü ildə Ankara, Bilkent Cyberpark’da qurulan Karmasis şirkəti SIEM, Database Activity Monitoring və Screen Recorder məhsulları ilə Türkiyədə 150-dən çox dövlət və özəl quruma xidmət göstərir. Karmasis, Kiber Təhlükəsizlik sahəsində Türkiyənin ən strateji qurumlarında istifadə olunur.
        Karmasis; başdan sona sistem qeydlərini idarə ede bilen və  son istifadəçidən məlumat bazasına qədər (end-to-end) hadisələri qeyd edə bilən Kiber ​​Təhlükəsizlik Sistemləri təklif edir.
        Son istifadəçi də daxil olmaqla SIEM məhsulunu SIEM++ kimi təqdim edən və DAM (Database Activity Monitoring) məhsulu ilə qlobal istehsalçılarla rəqabət aparan yeganə şirkətdir.
        Türkiyədə Kiber Təhlükəsizlik sahəsində tanınmış bir marka olan Karmasis, 100% Türk şirkətidir və Türk proqram təminatçıları tərəfindən hazırlanmış 100% yerli məhsulları təqdim edir.
        Çoxsaylı iş ortaqları və partnyor şirkətləri ilə birlikdə müştərilərinə çevik və sürətli xidmət göstərən Karmasis, fərqli ehtiyaclar üçün fərqli həllər istehsal edə bilir və bunları rəqiblərinə nisbətən çox daha sərfəli qiymətə həyata keçirir.
        Şirkətin səhmlərinin 51%-i Türkiyədə birjaya daxil olan ilk proqram təminatı şirkəti olan Kafein Yazılım A.Ş məxsusdur. Kafein, 700 ə yaxın işçisi və aparıcı proqram təminatı şirkətləri ilə Türkiyənin proqram nəhəngidir.
        Karmasis Azərbaycan 2021-ci ilin sentyabrından Azərbaycanda fəaliyyətə başlayıb.
      </MKTypography>
      </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
