/**
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { light } from "@mui/material/styles/createPalette";

/**
 * The base colors for the KARMASIS.
 * You can add new color using this file.
 * You can customized the colors for the entire KARMASIS using thie file.
 */

export default {
  background: {
    default: "#F5871D", // Main background can stay dark
  },

  text: {
    main: "#7b809a", // Can adjust for better contrast if needed
    focus: "#7b809a",
  },

  transparent: {
    main: "transparent",
  },

  white: {
    light:"#ffff",
    main: "FF7700",
    focus: "#ffff",
  },

  black: {
    light: "#000000",
    main: "#000000",
    focus: "#000000",
  },

  // Primary (brutal orange)
  primary: {
    main: "#e9573f", // Adjust saturation and lightness for your preference
    focus: "#e9573f",
  },

  // Secondary (muted indigo)
  secondary: {
    main: "#53647a",
    focus: "#6a7990",
  },

  info: {
    main: "#1A73E8", // Can be adjusted if it clashes with primary or secondary
    focus: "#1662C4",
  },

  success: {
    main: "#4CAF50",
    focus: "#67bb6a",
  },

  warning: {
    main: "#fb8c00",
    focus: "#fc9d26",
  },

  error: {
    main: "#F44335",
    focus: "#f65f53",
  },

  light: {
    main: "#f0f2f5",
    focus: "#f0f2f5",
  },

  dark: {
    main: "#F8973A", 
    focus: "#273246",
    brut:"black"
  },
  darkNav: {
    main: "#373435", 
    focus: "#273246",
    brut:"black"
  },

  grey: {
 100: "#fca605", // Adjust this value for a lighter orange (optional)
  200: "black", // Adjust this value for a slightly lighter orange (optional)
  300: "#fcad19", // Adjust this value for a medium-light orange (optional)
  400: "#f7af28", // Adjust this value for a medium orange (optional)
  500: "#dea02c", // You can keep this for a core orange
  600: "#facd7a", // Adjust this value for a slightly darker orange (optional)
  700: "#f7c25e", // Adjust this value for a dark orange (optional)
  800: "#f7bb4a", // Adjust this value for a very dark orange (optional)
  900: "#ffbd42", // Adjust this value for the darkest orange (optional)

  },

  gradients: {
    primary: {
      main: "#ec4e47", // More intense orange for gradient
      state: "#d73a37",
    },
    secondary: {
      main: "#6a84a1", // More blueish tint for gradient
      state: "#4b6380",
    },
    info: {
      main: "#ed7f1f",
      state: "#ff9c45",
    },
    success: {
      main: "#66BB6A",
      state: "#43A047",
    },
    warning: {
      main: "#FFA726",
      state: "#FB8C00",
    },
    error: {
      main: "#EF5350",
      state: "#E53935",
    },
    light: {
      main: "#ff9f29",
      state: "#f7af57",
    },
    dark: {
      main: "#303952", // Use your dark color for gradient
      state: "#303952",
    },
  },
  socialMediaColors: {
    facebook: {
      main: "#3b5998", // Replace with your desired color
      dark: "#344e86", // Replace with your desired color
    },
  
    twitter: {
      main: "#55acee", // Replace with your desired color
      dark: "#3ea1ec", // Replace with your desired color
    },
  
    instagram: {
      main: "#125688", // Replace with your desired color
      dark: "#0e456d", // Replace with your desired color
    },
  
    linkedin: {
      main: "#0077b5", // Replace with your desired color
      dark: "#00669c", // Replace with your desired color
    },
  
    pinterest: {
      main: "#cc2127", // Replace with your desired color
      dark: "#b21d22", // Replace with your desired color
    },
  
    youtube: {
      main: "#e52d27", // Replace with your desired color
      dark: "#d41f1a", // Replace with your desired color
    },
  
    vimeo: {
      main: "#1ab7ea", // Replace with your desired color
      dark: "#13a3d2", // Replace with your desired color
    },
  
    slack: {
      main: "#3aaf85", // Replace with your desired color
      dark: "#329874", // Replace with your desired color
    },
  
    dribbble: {
      main: "#ea4c89", // Replace with your desired color
      dark: "#e73177", // Replace with your desired color
    },
  
    github: {
      main: "#24292e", // Replace with your desired color
      dark: "#171a1d", // Replace with your desired color
    },
  
    reddit: {
      main: "#ff4500", // Replace with your desired color
      dark: "#e03d00", // Replace with your desired color
    },
  
    tumblr: {
      main: "#35465c", // Replace with your desired color
      dark: "#2a3749", // Replace with your desired color
    },
  },
  

  badgeColors: {
    primary: {
      background: "#e9573f", // Adjust for your brutal orange preference
      text: "#e9573f", // Adjust for your brutal orange preference
    },
  
    secondary: {
      background: "#53647a", // Adjust for your muted indigo preference
      text: "#53647a", // Adjust for your muted indigo preference
    },
  
    info: {
      background: "#1A73E8", // Can be adjusted if it clashes with primary or secondary
      text: "#1A73E8", // Can be adjusted if it clashes with primary or secondary
    },
  
    success: {
      background: "#4CAF50",
      text: "#4CAF50",
    },
  
    warning: {
      background: "#fb8c00",
      text: "#fb8c00",
    },
  
    error: {
      background: "#F44335",
      text: "#F44335",
    },
  
    light: {
      background: "#ffff",
      text: "#c7d3de",
    },
  
    dark: {
      background: "#8097bf",
      text: "#1e2e4a",
    },
  },
  
  coloredShadows: {
    primary: "#e91e62",
    secondary: "#110e0e",
    info: "#00bbd4",
    success: "#4caf4f",
    warning: "#ff9900",
    error: "#f44336",
    light: "#adb5bd",
    dark: "#404040",
  },
  
  inputBorderColor: "#d2d6da",
  
  tabs: {
    indicator: { boxShadow: "#f7c40a" },
  },
};
