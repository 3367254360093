/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto">
        <MKTypography variant="h3" mb={3}>
          AppTracker Xüsusiyyətləri
        </MKTypography>
        <MKTypography variant="body2">
        AppTracker daha ətraflı qeydlərə daxil ola bilər, çünki o, hadisənin baş verdiyi yerdə işləyir.
        AppTracker ilə izlənilə bilən hadisələrə nümunələr:
        <ul style={{marginLeft: "5%", marginTop: "2%"}}>
          <li>
            İstifadəçinin hansı proqramı nə qədər işlətdiyini izləmək — məsələn, Excel faylında kaç saat iş görülmüşdür?
          </li>
          <li>
            YouTube və ya sosial media saytlarında “aktiv” olaraq nə qədər vaxt sərf olunub?
          </li>
          <li>
            Gizli veb fəaliyyətlərinin monitorinqi  — Firewall / Proksi serverdən istifadə etmədən və ya VPN üzərindən edilen veb fəaliyyətlərin monitorinqi, kompüterə qoşulmuş mobil telefon vasitəsilə İnternetdə edilen əməliyyatların izlənməsi
          </li>
          <li>
            TOR, VPN, Periscope kimi proqramların istifadəsinə nəzarət
          </li>
          <li>
            Kompüterdə heç nə etmədən nə qədər vaxt sərf olundu?
          </li>
        </ul>
      </MKTypography>
    </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
