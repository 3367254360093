/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";

function AuthPages() {
  // pages images
  const img1 =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/sign-up-cover.jpg";
  const img2 =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/reset-cover.jpg";
  const img3 =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/account/signin-basic.jpg";

  return (
    <MKBox borderRadius="0.75rem" position="relative" variant="gradient" bgColor="dark" mt={{ xs: 0, lg: 12 }} mx={-2}>
      {/* <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width={{ xs: "100%", lg: "100%" }}
        height={{ xs: "100%", lg: "auto" }}
        opacity={0.6}
      /> */}
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            lg={12}
            sx={{ my: "auto", py: 6, pr: { xs: 3, lg: 2 }, pl: { xs: 3, lg: 0 } }}
          >
            <MKTypography variant="h3" color="white" mb={3}>
              Bilmədiyiniz təhlükələrə qarşı tədbir görə bilmərsiniz.
            </MKTypography>
            <MKTypography variant="h4" color="white" opacity={0.8} mb={2}>
              Bəlkə bu sətirləri oxuyarkən,
              şirkətinizin məlumat bazasından müştəri məlumatlarınız və ya ticarət sirrləriniz
              sızır. Gəlin qoruyucu qalxanlarınızı birlikdə yoxlayaq, təhlükələrin nə olduğunu göstərək və
              sərvət xərcləmədən ən effektiv müdafiəni edək.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AuthPages;
