/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// KARMASIS components
import MKBox from "components/MKBox";


// KARMASIS components
import SimpleBookingCard from "pages/Media/SimpleBookingCard";

// Images
import product1 from "assets/images/akta.jpeg";
import product2 from "assets/images/bwg.jpeg";
import product3 from "assets/images/dgka.jpeg";
import product4 from "assets/images/ktp.jpeg";
import product5 from "assets/images/ghw.jpeg";
import product6 from "assets/images/qomruk.jpg";
import product7 from "assets/images/qomruk2.jpg";
import product8 from "assets/images/trm.jpg";
import product9 from "assets/images/wbn.jpg";
import product10 from "assets/images/mus.jpg";
import product11 from "assets/images/adnsu.jpg";
import product12 from "assets/images/gulf.jpg";

function Places() {
  const bwgProps = {
    type: "internal",
    route: "https://www.businessworldglobal.com/veri-guvenliginde-lider-karmasis-ilk-yabanci-sirketi-ile-azerbaycanda-yerini-aldi/",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const dgkaProps = {
    type: "internal",
    route: "http://ascca.gov.az/az/news/638/karmasis",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const ktpProps = {
    type: "internal",
    route: "https://www.linkedin.com/feed/update/urn:li:activity:6982048136114372608/",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const aktaProps = {
    type: "internal",
    route: "https://akta.az/",
    color: "info",
    label: "Ətraflı Oxu",
  };

  const ghwProps = {
    type: "internal",
    route: "https://ghwsummit.com/",
    color: "info",
    label: "Ətraflı Oxu",
  };

  const qomrukProps = {
    type: "internal",
    route: "https://az.trend.az/azerbaijan/society/3714813.html",
    color: "info",
    label: "Ətraflı Oxu",
  };

  const qomruk2Props = {
    type: "internal",
    route: "https://moderator.az/az/cemiyyet/644374/turkiyenin-karmasis-sirketinin-mutexessisi-gmruk-akademiyasinda-muhazire-deyib-foto/",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const trmProps = {
    type: "internal",
    route: "https://buyuksehirhaber.net/turk-muhendisleri-ile-karmasis-azerbaycanda-genclerin-siber-guvenlik-alaninda-egitilmesi-istikametinde-calismalara-basladi/",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const wbnProps = {
    type: "internal",
    route: "https://rocb-europe.org/webinar-on-ensuring-border-security-through-advanced-information-and-cyber-security-measures-was-successfully-held",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const musProps = {
    type: "internal",
    route: "https://kaspi.az/hansisa-proqram-pulsuzdursa-emin-olun-ki-mehsul-sizsiniz-musahib",
    color: "info",
    label: "Ətraflı Oxu",
  };

  const gulfProps = {
    type: "internal",
    route: "https://infocity.tech/2024/02/gulfstream-distribution-stal-ofitsialnym-distribjutorom-karmasis-v-azerbajdzhane/",
    color: "info",
    label: "Ətraflı Oxu",
  };
  return (
    <MKBox component="section" py={3}>
      <Container>
        
        <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product12}
                title="Gulfstream Distribution стал официальным дистрибьютором Karmasis в Азербайджане"
                description=""
                action={gulfProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product10}
                title="“Hansısa proqram pulsuzdursa, əmin olun ki, məhsul sizsiniz” - MÜSAHİBƏ"
                description=""
                action={musProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product9}
                title="Webinar on Ensuring Border Security through Advanced Information and Cyber Security Measures was successfully held"
                description=""
                action={wbnProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product8}
                title="Türk Mühendisleri ile Karmasis Azerbaycan’da Gençlerin Siber Güvenlik Alanında Eğitilmesi İstikametinde Çalışmalara Başlad"
                description=""
                action={trmProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product7}
                title="Türkiyənin “Karmasis” şirkətinin mütəxəssisi Gömrük Akademiyasında mühazirə deyib"
                description=""
                action={qomruk2Props}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product6}
                title="Gömrük Akademiyasında “Cyber 360” kibertəhlükəsizlik tətbiq laboratoriyasının açılış mərasimi olub"
                description=""
                action={qomrukProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product2}
                title="Karmasis kiber təhlükəsizlik şirkəti, ilk xarici ofisini qardaş ölkə Azərbaycanda açdı"
                description=""
                action={bwgProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product3}
                title="Gömrük Akademiyası ilə “Karmasis Azərbaycan” şirkəti arasında görüş keçirilib"
                description=""
                action={dgkaProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product4}
                title="Karmasis Azərbaycan nümayəndə heyəti, Kiber Təhlükəsizlik Platformasının mərkəzini ziyarət etdilər"
                description=""
                action={ktpProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product5}
                title="Karmasis Azərbaycan, Global Hybrid Warfare and Cyber Security Summit-ə gold sponsor olaraq iştirak edir"
                description=""
                action={ghwProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product1}
                title="Karmasis Azərbaycan, Azərbaycan Kibertəhlükəsizlik Təşkilatları Assosiasiyası (AKTA) üzvü oldu"
                description=""
                action={aktaProps}
              />
            </MKBox>
          </Grid>
       
      
         
      
         
       
         
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
