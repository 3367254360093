/**
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
 * The base border styles for the KARMASIS.
 * You can add new border width, border color or border radius using this file.
 * You can customize the borders value for the entire KARMASIS using this file.
 */

// KARMASIS Base Styles
import colors from "assets/theme/base/colors";

// KARMASIS Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";

const { grey } = colors;

export default {
  borderColor: grey[300],

  borderWidth: {
    // 0: 0,
    // 1: pxToRem(0.5), // Thinner border width
    // 2: pxToRem(1),   // Thinner border width
    // 3: pxToRem(1.5), // Thinner border width
    // 4: pxToRem(2),   // Thinner border width
    // 5: pxToRem(2.5), // Thinner border width
  },

  borderRadius: {
    xs: pxToRem(1.6),
    sm: pxToRem(2),
    md: pxToRem(6),
    lg: pxToRem(8),
    xl: pxToRem(12),
    xxl: pxToRem(16),
    section: pxToRem(160),
  },
};
