/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useState } from "react";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  

  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
        Infraskope Agent İlə Ən Zəif Linki  İzləyin
      </MKTypography>
      <MKTypography variant="body2">
          <ul style={{marginLeft: "5%", marginTop: "2%", marginBottom: "2%"}}>
            <li>
            Bu sistemdən məlumatları necə oğurlaya bilərsiniz?
            </li>
            <li>
            Bu sistemi necə zədələyə bilərsiniz?
            </li>
            <li>
            Sistem admin hansı sahələr üzrə tədbir görmür?
            </li>
          </ul>
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      Təhlükədən xəbəriniz varmı?
      </MKTypography>
      <MKTypography variant="body2" my={2}>
          İnternet həyatımızın ən vacib texnologiyalarından biridir. Elektrik kimi, su kimi, çatışmayanda anlayırıq. Biz normal insanlar internetdən alış-veriş etmək, araşdırma aparmaq, videolara baxmaq və ya Google-dan istifadə etmək üçün istifadə edirik. Bəs pis adamlar? Terrorçular, informasiya oğruları, hakerlər bundan necə istifadə edirlər? Bomba resepti? Məxfi ünsiyyət? Qanunsuz maddə/avadanlıq təchizatı?
    </MKTypography>
      <MKTypography variant="body2" my={2}>
      Xəbərlərdə olub-olmamasından asılı olmayaraq hər gün bir çox internet saytları sındırılır, işdən çıxan işçi şirkət məlumatlarını özü ilə aparır və ya dünyanın bir yerində terror aktı baş verir. Bu insanlar hansı üsullardan istifadə edirlər? Necə olur ki, şirkətlər və ya məhkəmə bölmələri bu hadisələri aşkar edə bilmir?
    </MKTypography>
      <MKTypography variant="body2" my={2}>
      Çünki bu işə qarışanlar axmaq deyillər. Kameranın izlədiyi yerdə oğurluq edərdiniz? Yəqin ki, yox. Onlar da eyni şəkildə davranırlar. Məsələn, Google Sənədlər xidmətindən terror təşkilatı üzvləri tərəfindən kommunikasiya vasitəsi kimi istifadə edildiyini bilirdinizmi? Bəs şirkətinizdə belə hadisələr baş verərsə?
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      Təhlükəsizlik Fərgində Olmaqla Başlayır
      </MKTypography>
      <MKTypography variant="body2" my={2}>
      Təhlükəsiz olmaq üçün əvvəlcə təhlükənin nə olduğunu bilməlisiniz. Bilmədiyiniz təhlükəyə qarşı tədbir görə bilməzsiniz. Infraskope bu prinsiplə hazırlanmış məhsuldur. Infraskopeni tək bir cümlə ilə ümumiləşdirsək; "Bu, qaranlıqda yolunuzu tapmağa çalışdığınız zaman işığı yandıran kimidir."
    </MKTypography>
      <MKTypography variant="body2" my={2}>
      İnternetdən silah kimi istifadə etmək olarsa, ona hansısa şəkildə nəzarət edilməlidir. Infraskope, təşkilatınızda işləyən bütün sistemləri izləyən təhlükəsizlik kamerası kimi işləyir. Hadisələrə müdaxilə etmir, yalnız onların mərkəzləşdirilmiş şəkildə qeyd olunmasını təmin edir. Əhəmiyyətli hadisələr server tərəfində işlənir və real vaxt rejimində müvafiq şəxslərə xəbərdarlıq mesajları göndərilir.
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      Infraskope nəyi qeyd edir?
      </MKTypography>
      <MKTypography variant="body2" my={2}>
        Şirkətinizdə məlumat daşıyan sistemlərdə işləyən serverlər, şəbəkə cihazları, istifadəçi kompüterləri və proqramlar tərəfindən bildirilən bütün hadisələr qeydə alına bilər. Məsələn, korporativ kompüterlərdən birinə lisenziyasız proqram quraşdırmaq, daxil olmaq... Amma bu kifayət etsəydi, NSA, Sony, WikiLeaks kimi hadisələr baş verməzdi. 
    </MKTypography>
      <MKTypography variant="body2" my={2}>
        Bir anlıq düşünün; NSA kimi bir qurumdan 1,8 milyon məxfi sənəd sızdırılıb və heç kim bilmir! Sizcədə bu işdə bir səhv yoxdur?
      </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      “İstifadəçiləri qeydiyyatdan keçirmək istəmirik”
      </MKTypography>
      <MKTypography variant="body2">
      Səhv gedən budur! Sistem otağında serverlərin, kondisionerlərin və şəbəkə cihazlarının monitorinqi əməliyyat problemlərinə qarşı ehtiyat tədbirləri görməyə imkan verir. Məlumat sistem otağından çıxdıqda, yəni istifadəçinin kompüterinə aparıldıqda, sizin edə biləcəyiniz heç bir şey yoxdur. Bu səhvə yol verən təşkilatlar sonda NSA və bənzər qurumlarla eyni aqibəti yaşayacaqlar.
    </MKTypography>
    </Grid>
    <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
      <MKTypography variant="h3" mb={3}>
      İstifadəçilərin Fəaliyyətlərinin Qeydə Alınması
      </MKTypography>
      <MKTypography variant="body2">
      Sistem otağını, serverləri və içindəki cihazları izləmək kifayət deyil. Təhlükesizlik üçün istifadəçilər də izlənilməlidir. Infraskope, mikro-agent arxitekturası ilə istifadəçi kompüterlərinin fəaliyyətlərini də qeyd edir. Infraskope təkcə əməliyyat sistemi tərəfindən bildirilən hadisələri qeyd etmir, həm də çox daha vacib məlumat çıxış nöqtələrini izləməklə mümkün məlumat sızması cəhdlərini aşkarlayır. Məsələn:
          <ul style={{marginLeft: "5%", marginTop: "2%", marginBottom: "2%"}}>
            <li>
            İstifadəçilərinizin USB sürücüsünə kopyaladığı fayllar,
            </li>
            <li>
            Ekranda vacib bir görüntünün kopyalanması,
            </li>
            <li>
            Sistem inzibatçısı tərəfindən verilənlər bazasında icazəsiz fəaliyyətlər,
            </li>
            <li>
            Cib telefonlarını kompüterlərinə qoşaraq korporativ təhlükəsizlik siyasətlərindən yan keçməyə çalışan istifadəçilər,
            </li>
            <li>
            Sistem administratorunun girişi olmayan kompüterlərə daxil olmaq (məsələn, HR, R&D, Menecerlər),
            </li>
            <li>
            Bulud əsaslı sistemlərdə saxlanılan fayllar,
            </li>
            <li>
            KonBoot, ERDCommander və ya digər parol krakerləri ilə kompüterinizi sındırmaq
            </li>
          </ul>
          Yuxarıda yalnız bir neçəsini sadaladığımız təhlükələrin aşkarlanması Infraskope-ni bütün digər məhsullardan fərqləndirən unikal xüsusiyyətlərdir. Ancaq təkcə bu deyil, Infraskope bir çox başqa xüsusiyyətləri təklif edir:
          <ul style={{marginLeft: "5%", marginTop: "2%", marginBottom: "2%"}}>
            <li>
              <span style={{fontWeight: "bold"}}>İşçilərinizin vaxtını hansı tətbiqlərə sərf etdiyini izləmək:</span> Bu xüsusiyyət sizə performansın idarə edilməsini daha ədalətli şəkildə həyata keçirməyə imkan verir. Aygün, günün yarısını alış-veriş saytlarını gəzərək keçirir, Samir gündə 12 saat Excel-ə məlumat daxil edir... İndi ikisini bir-birindən ayırmaq mümkündür.
            </li>
            <li>
              <span style={{fontWeight: "bold"}}>Müəssisə proqram təminatı və aparat inventarları:</span> Bu funksiya ilə siz korporativ təhlükəsizlik siyasətlərinizə nəzarət edə, pirat proqram təminatı istifadəsindən dərhal xəbərdar ola və onlara qarşı tədbir görə bilərsiniz.
            </li>
            <li>
              <span style={{fontWeight: "bold"}}>Kompüterlərdə aparat dəyişikliyi barədə real vaxt bildirişi:</span> Ayrılmaq üzrə olan işçi 2000 dollarlıq qrafik kartını 30 dollarlıq kartla əvəz etdi? Siz bunu həmin vaxt görə bilərsiniz.
            </li>
            <li>
              <span style={{fontWeight: "bold"}}>Arzuolunmaz proqramların istifadəsinin bloklanması:</span> Bu funksiya potensial zərərli proqram təminatını bloklamağa imkan verir. Məsələn, uzaqdan idarə olunan proqramlar, antivirus tərəfindən hələ aşkar edilməyən yeni çıxan troyan proqramı bu üsulla dayandırıla bilər.
            </li>
          </ul>
    </MKTypography>
    </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
