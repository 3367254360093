/* eslint-disable import/no-unresolved */
/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef, useState } from "react";

// SwiperJS
import SwiperCore, { Autoplay, Navigation } from "swiper";

// SwiperJS react components
import { Swiper, SwiperSlide } from "swiper/react";

// SwiperJS styles
import "swiper/css";
import "swiper/css/navigation";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import siemIllustrator from "assets/images/infraskope-siem-grafik.jpg";
import datascopeIllustrator from "assets/images/dataskope-grafik.jpg";
import recorderIllustrator from "assets/images/screen-recorder-grafik.jpg"

function Steps() {
  // install SwiperJS modules
  SwiperCore.use([Autoplay, Navigation]);

  // SwiperJS navigation buttons ref
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const [swiperEl, setSwiperEl] = useState(null);

  const slideTo = (index) => swiperEl && swiperEl.slideTo(index);

  const slides = [
    {
      image: siemIllustrator,
      label: "Infraskope SIEM++",
      title: "Infraskope Log İdarə Sistemi",
      description: "Infraskope, əməliyyat sistemlərini, şəbəkə qurğularını və tətbiqlərini izləyən bir qeyd idarəetmə vasitəsidir. Həmin mənbələrdən toplanan qeydləri sorğulamağa, təhlil etməyə və təhlükə yarada biləcək qeydlər üçün xəbərdarlıq mexanizmlərini idarə etməyə imkan veren sistemdir.",
    },
    {
      image: datascopeIllustrator,
      label: "Dataskope DAM",
      title: "Əsas Xüsusiyyətləri və Faydalar",
      description: "DATASKOPE məhsulu, verilənlər bazası sistemlərinizdə baş verən hadisələri aşkarlayır, təsnif edir, həyəcan siqnalları yaradır və xəbər verir. Həssas məlumatlara kimin daxil olduğu və hansı fasilələrlə hansı tədbirlərin görüldüyü barədə məlumatlara asanlıqla daxil olmağa imkan verən , əvvəlcədən təyin edilmiş siqnalizasiya qaydalarıyla səlahiyyətli şəxslərin hadisələr haqqında məlumatlandırılmasını təmin edən bir verilənlər bazası audit məhsuludur.",
    },
    {
      image: recorderIllustrator,
      label: "Infraskope Screen Recorder",
      title: "Bir Baxışda",
      description: "Bu, kritik serverlərdə və ya iş stansiyalarında baş verən əməliyyatları mərkəzləşdirilmiş şəkildə qeyd etməyə imkan verir. O, sizə uzaqdan dəstək verən şirkətlərin və ya şirkətdə/qurumda çalışan “xarici” personalın əməliyyatlarına nəzarət etməyə kömək edir.",
    },
  ];

  // Swiper navigation buttons styles
  const navigationStyles = {
    opacity: 0.5,
    cursor: "pointer",
    transition: "opacity 0.15s ease",

    "&:hover, &:focus": {
      opacity: 1,
    },
  };

  return (
    <MKBox borderRadius="0.75rem" component="section" py={12} position="relative">
      <Container>
        <Swiper
          onInit={(swiper) => {
            setSwiperEl(swiper);

            const { navigation: nav } = swiper.params;
            const { navigation } = swiper;

            nav.prevEl = navigationPrevRef.current;
            nav.nextEl = navigationNextRef.current;
            navigation.init();
            navigation.update();
          }}
          autoplay={{ delay: 5000 }}
          speed={800}
          spaceBetween={0}
          slidesPerView={1}
          allowTouchMove={false}
          loop
        >
          {slides.map(({ image, label, title, description }) => (
            <SwiperSlide key={label}>
              <Grid container spacing={3} alignItems="center" sx={{ mb: { xs: 6, md: 0 } }}>
                <Grid item xs={12} md={5} ml={{ xs: 0, lg: "auto" }}>
                  <MKBox p={2}>
                    <MKBox
                      component="img"
                      src={image}
                      alt={title}
                      width="100%"
                      borderRadius="xl"
                      maxHeight="37.5rem"
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={5} mr={{ xs: 0, lg: "auto" }} position="relative">
                  <MKTypography
                    component="h3"
                    variant="h3"
                    opacity={0.7}
                    textTransform="uppercase"
                    fontWeight="bold"
                    my={3}
                  >
                    {label}
                  </MKTypography>
                  <MKTypography
                    variant="h4"
                    fontWeight="bold"
                    sx={{
                      fontSize: "30px",
                    }}
                  >
                    {title}
                  </MKTypography>
                  <MKTypography variant="body1" my={3} sx={{fontSize: "18px"}}>
                    {description}
                  </MKTypography>
                </Grid>
              </Grid>
            </SwiperSlide>
          ))}
          <MKBox
            display="flex"
            position="absolute"
            bottom={{ xs: 0, lg: "10%" }}
            right="5%"
            zIndex={2}
          >
            <MKTypography
              variant="h2"
              ref={navigationPrevRef}
              color="dark"
              mr={{ xs: 8, md: 2, lg: 8 }}
              sx={navigationStyles}
            >
              <MKBox className="fas fa-chevron-left" />
            </MKTypography>
            <MKTypography variant="h2" ref={navigationNextRef} color="dark" sx={navigationStyles}>
              <MKBox className="fas fa-chevron-right" />
            </MKTypography>
          </MKBox>
        </Swiper>
      </Container>
    </MKBox>
  );
}

export default Steps;
