/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// KARMASIS examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "pages/Blogs/SingleArticle/sections/Information";


// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg5.jpg";

const pathname = window.location.href;
let bgHeader;
let bgDescription;
const content = {};

if (pathname.includes("/product/log-cather")) {
  bgHeader = "Log Toplama";
  bgDescription = `
  Infraskope AppTracker, istifadəçi və server sistemlərində hansı istifadəçinin hansı proqramdan və 
  nə qədər müddətə aktiv şəkildə istifadə etdiyini qeyd edir.
  `;
} else if (pathname.includes("/product/dataskope-database")) {
  bgHeader = "Dataskope – Database Activity Monitoring and Prevention";
  bgDescription = "DATASKOPE məhsulunun əsas funksiyası kritik verilənlər bazası sistemlərinə girişlərin və dəyişikliklərin gözlənildiyi, planlaşdırıldığı və arzu edildiyi kimi olmasını təmin etməkdir. Siz Dataskope ilə bütün bu əməliyyatları verilənlər bazasındakı audit funksiyasını işə salmadan edə bilər və beləliklə, sistem performansını azaltmadan audit yoxlanışlarını keçirtə bilərsiniz.";
}

function SingleArticle() {
  const headerRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });


    return () => parallax.destroy();
  }, []);

  return (
    <>
      <MKBox bgColor="white" py={0.25}>
        <DefaultNavbar
          routes={routes}
          transparent
          relative
        />
      </MKBox>
      <MKBox
        ref={headerRef}
        minHeight="85vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.8),
              rgba(gradients.dark.state, 0.8)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" flexDirection="column">
            <MKTypography
              variant="h1"
              color="white"
              mb={2}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {bgHeader}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              opacity={0.8}
              mb={2}
              mr={{ xs: 0, sm: 6 }}
              pr={{ xs: 0, sm: 6 }}
            >
              {bgDescription}
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information/>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default SingleArticle;
