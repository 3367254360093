/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6} mt={6}>
      <Container>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Üstünlüklər Və Xüsusiyyətlər
        </MKTypography>
        <MKTypography variant="body2">
        <ul style={{marginLeft: "5%", marginTop: "2%"}}>
          <li>
            Ekran Görüntüsünü Qeyd Edər, Log Faylları İlə Vaxt İtirməyinizə Gərək Yoxdur
          </li>
          <li>
            ISO 27001 Kimi Beynəlxalq Təhlükəsizlik Standartlarına Uyğun Olmaqda Sizə Kömək Edir
          </li>
          <li>
          Nəzarət Edilən İstifadəçilərin Kompüterində Müəyyən Etdiyiniz Açar Sözlərdən İstifadə Ediləndə Sizə Avtomatik Xəbərdarlıq Verir
          </li>
        </ul>
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Kritik serverlərin və iş stansiyalarının yoxlanılması
        </MKTypography>
        <MKTypography variant="body2">
        Kritik verilənlərə malik sistemlərdə həyata keçirilən əməliyyatların monitorinqi təhlükəsizlik və işin davamlılığı üçün son dərəcə vacibdir. Infraskope Screen Recorder ilə sistem administratorlarının (yerli/RDP) və uzaqdan dəstək (RDP) təmin edən şirkət işçilərinin müvafiq sistemlərdə nə etdiklərini izləmək mümkün olur.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        İstifadəçi əsaslı qeyd rejimi
        </MKTypography>
        <MKTypography variant="body2">
        Infraskope Screen Recorder ilə siz müəyyən istifadəçilərin seanslarına nəzarət edə bilərsiniz. Bu yolla siz hansı sistemə qoşulmasından asılı olmayaraq uzaqdan dəstək verən şirkətlərin işçilərini qeyd edə bilərsiniz.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Açar Söz İzləmə
        </MKTypography>
        <MKTypography variant="body2">
        Infraskope Screen Recorder seanslarda müəyyən açar sözlər qeyd edildikdə avtomatik olaraq xəbərdarlıqlar yarada bilər. Bu funksiya ilə @hotmail.com, @gmail.com, bomba, "rəqib" kimi sözləri avtomatik izləyə bilərsiniz.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Söz Axtarışı, Sürətli Oynatma və Video İxrac
        </MKTypography>
        <MKTypography variant="body2" my={2}>
        Infraskope Screen Recorder seanslar daxilində sözləri axtarmağa imkan verir. Məsələn, bir araşdırmaya gəlincə, “baş nazir”in hansı iclaslardan keçdiyini saniyələr içində müəyyən edə bilərsiniz.
      </MKTypography>
        <MKTypography variant="body2" my={2}>
        Səkkiz saatlıq seansa baxmağa səkkiz saat sərf etmək lazım deyil. Təkmil oxutma seçimləri sayəsində siz seansları sürətli oynaya və ya sadəcə fəaliyyət anlarını izləyə bilərsiniz. Bundan əlavə, vacib hesab etdiyiniz sessiyanı müxtəlif formatlarda video kimi ixrac edə bilərsiniz.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Korporativ yaddaşın yaradılması
        </MKTypography>
        <MKTypography variant="body2" my={2}>
        Infraskope Screen Recorder həmçinin hadisəyə reaksiya zamanı hansı hərəkətlərin edildiyini qeyd etmək üçün istifadə edilə bilər. Bu şəkildə bir növ video təlim kitabxanası yarada bilərsiniz.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Aşağı resurs istehlakı
        </MKTypography>
        <MKTypography variant="body2" my={2}>
        Infraskope Screen Recorder C++ dilində hazırlanmışdır. Bu şəkildə CPU və yaddaş istehlakı son dərəcə aşağıdır. Normal seans zamanı CPU istifadəsi təxminən 1% təşkil edir.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={2}>
        <MKTypography variant="h3" mb={3}>
        Aşağı saxlama sahəsi tələbi
        </MKTypography>
        <MKTypography variant="body2" my={2}>
        Infraskope Screen Recorder, nəzarət edilən cihazda heç bir fəaliyyət olmadıqda qeyd etmir. Bu, disk sahəsi tələbini minimuma endirir. Tipik bir video qeyd proqramı dəqiqədə təxminən 6 MB tələb edir. Infraskope Screen Recorder ilə siz təxminən 10-15 MB sahədə bir saatlıq sessiyaya qənaət edə bilərsiniz.
      </MKTypography>
      </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
