/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// KARMASIS components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// KARMASIS examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Image
import bgImage from "assets/images/bg-contact-us.jpg";

function ContactUs() {
  return (
    <>
      <MKBox position="fixed" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={routes}
          action={{
            type: "external",
            route: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
            label: "buy now",
            color: "info",
          }}
        />
      </MKBox>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})`, backgroundSize: "cover" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="black"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
              style={{ backgroundColor: "black" }}
            >
              <MKTypography variant="h3" color="orange">
                Bizimlə Əlaqə
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox width="100%" component="form" method="post" autocomplete="off">
                <Grid container spacing={3}>
               
                  <Grid item xs={12} md={12}>
                    <MKTypography variant="body1">
                      E-Poçt: info@karmasis.com
                    </MKTypography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <MKTypography variant="body1">
                      Ünvan: Cəlil Məmmədquluzadə 102A.<br />
                      City Point Baku 8-ci mərtəbə
                    </MKTypography>
                  </Grid>
                </Grid>
              </MKBox>
            </MKBox>
            <iframe
  title="map"
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.8505559392293!2d49.82759667713996!3d40.39000465726366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d82b3b0c031%3A0xc11310c761bdb24!2sCity%20Point%20Baku!5e0!3m2!1str!2saz!4v1717510630916!5m2!1str!2saz"
  width="100%"
  height="200"


  style={{ border: "2px solid black",borderRadius:"5px", allowFullScreen: true }} 
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>

          </MKBox>

        </Grid>

      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ContactUs;
