/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useRef, useEffect} from "react";

import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// KARMASIS components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import MKSocialButton from "components/MKSocialButton";

// KARMASIS examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import AuthPages from "pages/Presentation/sections/AuthPages";
import Steps from "pages/Presentation/sections/Steps";
// import Contact from "pages/LandingPages/Rental/sections/Contact";

// Presentation page components
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-presentation.gif";

function Presentation() {
  const typedJSRef = useRef(null);

  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["FƏRQİNDƏ OLMAQLA BAŞLAR ™"],
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
      <div className="navbar">
        <DefaultNavbar
          routes={routes}
          sticky
          className="navbar"
        />
      </div>
      <MKBox
        minHeight="80vh"
        width="100%"
        sx={{
          backgroundImage: `linear-gradient(to left, rgba(255, 255, 255, 0), rgba(0, 0, 0, .9), rgba(0, 0, 0, .9), rgba(255, 255, 255, .1)), url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto" flexDirection="column">
            <MKTypography variant="body1" color="white" textAlign="center" px={6} mt={1} fontSize="35px" fontWeight="bold">
              TƏHLÜKƏSİZLİK,
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" px={6} mt={1} fontSize="35px" fontWeight="bold">
                <span ref={typedJSRef} />
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Steps />
        <Container>
          <AuthPages />
          {/* <MKBox pt={18} pb={6}> */}
            {/* <Contact /> */}
          {/* </MKBox> */}
        </Container>
        <Container sx={{ mt: 6 }}>
          <BuiltByDevelopers />
        </Container>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                variant="#271834"
                color="#271834"
                icon=""
                title="AppTracker"
                description=""
                action={{
                  type: "external",
                  route:
                    "/product/app-tracker",
                  label: "Ətraflı Oxu",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FilledInfoCard
                variant="#271834"
                color="#271834"
                icon=""
                title="Infraskope Agent"
                description=""
                sx= {{
                  backgroundColor: "#acaca5 !important"
                }}
                action={{
                  type: "external",
                  route:
                    "/product/infraskope-agent",
                  label: "Ətraflı Oxu",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Presentation;
