/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Collapse } from "@mui/material";
import Icon from "@mui/material/Icon";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const [checked, setChecked] = useState(false);
  const [rotationDeg, setRotationDeg] = useState("-90deg");

  const handleChange = () => {
    if(checked) {
      setRotationDeg("-90deg");
    } else {
      setRotationDeg("0deg")
    }
    setChecked((prev) => !prev);
  }

  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      onMouseEnter={handleChange}
      onMouseLeave={handleChange}
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={10} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h3" color="white" mb={1}>
            Güvən yaxşıdır, amma həddindən artıq güvən gəmini batırır.
          </MKTypography>
          <MKTypography 
            variant="p"
            width="100%"
            color="white"
            mb={1}
            display="flex"
            justifyContent="end">
            - Oscar Wilde
          </MKTypography>
          <Icon fontSize="large" style={{position: "absolute", right: "7%", color: "white", transform: `rotate(${rotationDeg})`}}>keyboard_arrow_down_icon</Icon>
          <MKTypography variant="body1" color="white" mb={2}>
            <Collapse in={checked}>
            Bizi məlumat pozuntularından qorumaq üçün əsasən texnologiyaya güvənsək də, bu gün yaşanan kiber təhlükəsizlik pozuntularının 95%-ni insan səhvi təşkil edir. İlk növbədə buna görə şirkət işçilərini öyrətmək lazım olsa bələ, insan səhvlərinin qarşısını almaq üçün işçilərə təlimlərin keçirilməsi kifayət deyildir. Sosial mühəndislik, qisas, casusluq, səhv yazılmış kod parçası… Onların hamısı insanları və onların zəif tərəflərini hədəf alır.
            </Collapse>
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
