/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ColumnGroupingTable from "pages/Blogs/SingleArticle/InfraskopeSiem/sections/ColumnGroupingTable";

import { useState } from "react";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  

  return (
      <MKBox component="section" py={6} mt={6}>
        <Container>
        <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        ƏN ZƏİF LİNKİ İZLƏYİN
        </MKTypography>
        <MKTypography variant="body2">
        İnsan amili, təhlükəsizlik zəncirinin ən zəif halqasıdır. Bütün kiber hücum vektorları insan səhvindən istifadə edir. Sosial mühəndislik, qisas, casusluq, səhv yazılmış kod parçası... Onların hamısı insanları və onların zəif tərəflərini hədəf alır. Siz Infraskope SIEM++ ilə, yalnız sistem otağınızdakı serverlər, şəbəkə cihazları və proqramları deyil, həm də ən zəif əlaqə olan son istifadəçilərə nəzarət ede bilersiniz.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        INFRASKOPE LOG İDARƏ SİSTEMİ
        </MKTypography>
        <MKTypography variant="body2">
        Infraskope, əməliyyat sistemlərini, şəbəkə qurğularını və tətbiqlərini izləyən bir qeyd idarəetmə vasitəsidir. Həmin mənbələrdən toplanan qeydləri sorğulamağa, təhlil etməyə və təhlükə yarada biləcək qeydlər üçün xəbərdarlıq mexanizmlərini idarə etməyə imkan veren sistemdir.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        NİYƏ INFRASKOPE?
        </MKTypography>
        <MKTypography variant="body2">
            Xarici ve qlobal məhsullar çox olduğu halda niyə Infraskope? Çünki Infraskope ilə siz həm də təcrübə alırsınız. Hansı hadisələrin hansı əməliyyat sistemindən toplanacağını, hansıların vacib və ya əhəmiyyətsiz olduğunu, qeydləri toplamaq üçün edilməli olan infrastruktur dəyişikliklərini və s. haqqında araşdırma aparmağa ehtiyac yoxdur. Bu qaydalar Infraskope quraşdırıldıqda istifadəyə hazırdır.
  Bundan əlavə, movcud məhsul təklifindən kənara çıxa bilməyən qlobal şirkətlərdən fərqli olaraq, Karmasis müştərilərin ehtiyac və tələblərinə uyğun həllər yarada və fərdiləşdirə bilər.

      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        NoSQL Axtarış Motoru (Elastic Search) 
        </MKTypography>
        <MKTypography variant="body2">
            <ul style={{marginLeft: "5%", marginTop: "2%"}}>
              <li>
              Üfüqi və şaquli olaraq ölçülə bilən (vertical and horizontal)  
              </li>
              <li>
              Coğrafi və yerli yedəkləmə
              </li>
              <li>
              Bütün regionlarınızda paralel axtarış
              </li>
              <li>
              Avtomatik yedəkləmə və arxivləşdirmə
              </li>
            </ul>
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        Həssas məlumatların avtomatik maskalanması
        </MKTypography>
        <MKTypography variant="body2">
            <ul style={{marginLeft: "5%", marginTop: "2%"}}>
              <li>
              Şəxsiyyət vesəqəsinin tanınması və maskalanması
              </li>
              <li>
              Kredit kartının tanınması və maskalanması
              </li>
              <li>
              E-poçtun tanınması və maskalanması
              </li>
              <li>
              RegEx (Daimi İfadələr) dəstəyi
              </li>
            </ul>
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        INFRASKOPE KOLLEKTOR ÇƏRÇİVƏSİ
        </MKTypography>
        <MKTypography variant="body2">
          Infraskope Collector Framework, Windows EventLog qeydlərindən başqa hadisələri Infraskope Server verilənlər bazasına köçürmək üçün istifadə olunur.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        LOG MƏNBƏLƏRİ
        </MKTypography>
        <MKTypography variant="body2">
            Infraskope Collector Framework müxtəlif log mənbələrindən qeydləri oxuya bilər. Infraskope Collector Framework tərəfindən dəstəklənən resurslar aşağıda verilmişdir:
            <ul style={{marginLeft: "5%", marginTop: "2%"}}>
              <li>
              EventLog
              </li>
              <li>
              Mətn faylı əsaslı qeydlər (W3C, IIS, LOG, CSV, TXT, TSV və s.)
              </li>
              <li>
              Syslog (UDP və TCP)
              </li>
              <li>
              SNMP
              </li>
              <li>
              WMI
              </li>
              <li>
              OPSEC LEA
              </li>
              <li>
              Verilənlər bazası (MSSQL, ORACLE, MYSQL, ODBC və s.)
              </li>
              <li>
              Xüsusi sistemlər (məhsuldan asılı olan xüsusi log toplama üsulu)
              </li>
            </ul>
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        Pulsuz Kollektor İnkişafına Zəmanət Verilir!
        </MKTypography>
        <MKTypography variant="body2">
        Infraskope tərəfindən dəstəklənməyən bir məhsulunuz varsa, biz müvafiq kollektoru pulsuz hazırlayırıq.
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        DAHA SƏRFƏLİ
        </MKTypography>
        <MKTypography variant="body2">
            <ul style={{marginLeft: "5%", marginTop: "2%"}}>
              <li>
              400 verilənlər bazası sisteminin qeydlərini aparmaq üçün 100 ədəd loq toplama serverinə ehtiyac yoxdur!
              </li>
              <li>
              İstifadəçi dəyişikliklərini aşkar etmək üçün əlavə modullar almaq lazım deyil!
              </li>
              <li>
              Serveriniz sürətli  olduğu üçün lazımsız ödənişlər etmirsiniz!
              </li>
              <li>
              EPS yoxdur. Verilənlər bazası və server əsaslı lisenziya seçimləri var!
              </li>
            </ul>
      </MKTypography>
      </Grid>
      <ColumnGroupingTable />
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        SISTEM TƏLƏBLƏRI
        </MKTypography>
        <MKTypography variant="body2">
        İnfraskop Quraşdırması üçün tələb olunan aparat və proqram təminatı tələbləri aşağıda verilmişdir:
        <h4 style={{fontWeight: "bold"}}>Server Hardware (Server Avadanlığı)</h4>
        <ul style={{marginLeft: "5%", marginTop: "2%"}}>
          <li>
          64bit processor
          </li>
          <li>
          16/32/64GB RAM (small/medium/large installs)
          </li>
          <li>
          1280×800 or higher resolution video card
          </li>
          <li>
          2 x GBIT network cards
          </li>
          <li>
          Hard Disks (Physically separate disk):
            <ul style={{marginLeft: "5%"}}>
              <li>
              System: 100GB disk (SATA/SAS/SCSI)
              </li>
              <li>
              Queue: 64GB disk (SSD recommended for large installations)
              </li>
              <li>
              Database: 500GB+ RAID5 or NAS/SAN disk space (more detailed in Disk Requirements)
              </li>
            </ul>
          </li>
        </ul>
        <h4 style={{fontWeight: "bold", marginTop: "40px"}}>OPERATING SYSTEM AND COMPONENTS (Əməliyyat sistemi və komponentləri)</h4>
        <ul style={{marginLeft: "5%", marginTop: "2%"}}>
          <li>
            Windows Server 2012 R2
            <ul style={{marginLeft: "5%"}}>
              <li>
                Make sure that the GPOs applied to the organizational-unit where the installed server is located do not restrict system components or access rights.
              </li>
            </ul>
          </li>
          <li>
          Microsoft .NET Framework 4.7
          </li>
          <li>
          Required Operating System Components
          <ul style={{marginLeft: "5%"}}>
            <li>IIS</li>
            <li>MSMQ</li>
          </ul>
          </li>
          <li>
          Java Server Runtime (latest version – required for Infraskope 2016)
          </li>
          <li>
          Microsoft SQL Server Express Edition (2012+)
          <ul style={{marginLeft: "5%"}}>
            <li>
            Database Engine
            </li>
            <li>
            LATIN1_GENERAL_CI_AS Collation
            </li>
          </ul>
          </li>
        </ul>
      </MKTypography>
      </Grid>
      <Grid container spacing={3} item xs={12} lg={8} mx="auto" my={4} display="flex" flexDirection="column">
        <MKTypography variant="h3" mb={3}>
        DISK REQUIREMENTS (Disk Tələbləri)
        </MKTypography>
        <MKTypography variant="body2">
              <p style={{marginBottom: "20px"}}>Aşağıdakı cədvəl tipik istifadə üçün tövsiyə olunan disk tələblərini göstərir.</p>
              <p style={{marginBottom: "20px"}}>Performans baxımından ən vacib komponent disk sistemləridir. Xüsusiyyətlər MSMQ xidməti tərəfindən istifadə olunan kataloqun sürətli diskdə olması tövsiyə olunur və mümkünsə, disklərin Write-Cache funksiyasını aktivləşdirin. SSD böyük quraşdırmalar üçün çox tövsiyə olunur.</p>
              <p>QEYD: İstifadə olunan kollektorlar bu tələbləri dəyişə bilər.</p>

      </MKTypography>
      </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
