/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// KARMASIS components
import MKBox from "components/MKBox";
import MKPagination from "components/MKPagination";

// KARMASIS components
import SimpleBookingCard from "pages/Demo/SimpleBookingCard";

// Images
import product1 from "assets/images/akta.jpeg";
import product2 from "assets/images/bwg.jpg";
import product3 from "assets/images/dgka.jpeg";
import product4 from "assets/images/ktp.jpeg";
import product5 from "assets/images/ghw.jpeg";

function Places() {
  const bwgProps = {
    type: "internal",
    route: "https://www.youtube.com/embed/GfIGFsj8EnQ",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const dgkaProps = {
    type: "internal",
    route: "https://www.youtube.com/embed/J252qyoC_R8",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const ktpProps = {
    type: "internal",
    route: "https://www.youtube.com/embed/T36c24gAQXQ",
    color: "info",
    label: "Ətraflı Oxu",
  };
  const aktaProps = {
    type: "internal",
    route: "https://www.youtube.com/embed/rqERJQQvbnE",
    color: "info",
    label: "Ətraflı Oxu",
  };

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product2}
                title="infraskope SIEM++ performance"
                description=""
                action={bwgProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product3}
                title="Infraskope Search Performance"
                description=""
                action={dgkaProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product4}
                title="Dataskope 2114 - 2"
                description=""
                action={ktpProps}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MKBox mt={3}>
              <SimpleBookingCard
                image={product5}
                title="AppTracker"
                description=""
                action={aktaProps}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Places;
