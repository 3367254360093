import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState, useEffect } from 'react';
import { Icon, Collapse } from '@mui/material';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
];

function createData(name, code) {
  return { name, code };
}

const rowsForSystemArch = [
  createData('Scalability (Ölçeklenebilirlik)', 'Vertical and Horizontal Growth (Şaquli və Horizontal Artım)'),
  createData(`Data storage
  (Məlumat saxlama)
  `, 'ElasticSearch (NOSQL)'),
  createData(`Virtualization support
  (Virtuallaşdırma dəstəyi)
  `, 'Microsoft HyperV and VMWare ESX'),
  createData(`Agent-to-Server communication
  (Agent-Server rabitəsi
  `, "XML Web Services, port 80/443"),
  createData(`Collector-to-Server communication
  (Kollektor-Server rabitəsi)
  `, 'TCP 1801'),
  createData(`Communication security
  (Rabitə təhlükəsizliyi)
  `, 'SSL 128 byte (optional)'),
  createData(`Log Collection Methods
  (Log/Qeydləri Toplama Metodları)
  `, 'With and/or Without Agent (Remote)'),
  createData(`Ensuring log integrity
  (Jurnal bütövlüyünün təmin edilməsi)
  `, `Hash based signature, X509 certificate, Qualified Timestamp (optional)`),
];

const rowsForLogCollection = [
  createData(`Central rule definition
  (Mərkəzi qayda tərifi)
  `, `YES`),
  createData(`Filter event at source
  (Mənbədə hadisəni süzün)
  `, `YES (On systems with agents)
  (Agentləri olan sistemlərdə)
  `),
  createData(`Store and send in case of network outage
  (Şəbəkə kəsiləndə halında saxla və göndər)
  `, 'YES'),
  createData(`Normalization
  (Normallaşdırma)
  `, "YES"),
  createData(`Labeling / Classification
  (Etiketləmə / Təsnifat)
  `, "YES"),
  createData(`Association (correlation)`, "YES"),
  createData(`Agent and collector operation monitoring and response
  (Agent və kollektor əməliyyatlarının monitorinqi və cavabı)
  `, "YES"),
  createData(`Alert methods
  (Xəbərdarlıq üsulları)
  `, `(Vizual (pop-up), E-poçt, Proqram/Skript Run, SMS göndərilməsi)`),
  createData(`Can use event context as alert parameter
  (Hadisə kontekstindən xəbərdarlıq parametri kimi istifadə edə bilər)
  `, "YES"),
  createData(`Active Directory integrated parameter resolution
  (Active Directory inteqrasiya edilmiş parametr həlli)
  `, "YES"),
  createData(`Ability to warn user and administrator
  (İstifadəçi və administratoru xəbərdar etmək imkanı)
  `, "YES"),
  createData(`Ability to warn server administrator
  (Server administratorunu xəbərdar etmək imkanı)
  `, "YES")
];

const rowsForSupportedLogSources = [
  createData('Microsoft Windows', `Windows XP, Windows Vista, Windows 7, Windows 10, Windows Server 2003, Windows Server 2008R2 (32 ve 64 bit), Windows Server 2012R2+`),
  createData('UNIX Platform', "HP UX, Solaris, RedHat Linux, CentOS, SuSe Linux, Oracle Enterprise Linux, Ubuntu and other common UNIX-based operating systems"),
  createData("Network Devices", "All network devices supporting Syslog and SNMP"),
  createData("Firewall/Proxy", "YES"),
  createData(`Labeling / Classification
  (Etiketləmə / Təsnifat)
  `, "Microsoft ISA / TMG Server, SQUID, DansGuardian, CheckPoint, Cisco PIX/FWSM/ASA, Juniper, Fortigate, WebSense, …"),
  createData("Email Services", "Exchange Server 2003 / 2007/ 2010, Lotus Domino, Qmail, SendMail and other UNIX based email servers"),
  createData("Application Servers", "IIS 5.0, 6.0, 7.0 formatları, Apache, TomCat, Jboss and other text or syslog based application servers"),
  createData("Database Servers", "Major DBMS applications, especially Microsoft SQL Server, Oracle, IBM DB/2, SyBase"),
  createData(`Intrusion Detection / Prevention (IDS/IPS)
  (İntruziyanın aşkarlanması/qarşısının alınması(IDS/IPS))
  `, "ISS, Proventia, Snort,and others")
];

const rowsForPresetAlertRules = [
  createData(`Pre-Set Alert Rules (Hazır Xəbərdarlıq Qaydaları)`, `İstifadəçi və ya qrup yaradıldıqda, dəyişdirildikdə və ya silindikdə sistem administratorlarına məlumat verilməsi`),
  createData("Ekran görüntüsünün çəkilməsi", "Prnt-Scrn düyməsi və ya ekran çəkmə proqramları ilə çəkilmiş skrinşotların mərkəzləşdirilmiş şəkildə saxlanılması və araşdırılması"),
  createData("USB saxlama cihazları", "USB yaddaş cihazlarına kopyalanan faylların izlənməsi"),
  createData("Tətbiqlərin idarə edilməsi (Application Management)", "Müəyyən etdiyiniz proqramlar işə salındıqda sistem administratorlarına xəbərdarlıq göndərmək və tətbiqi dayandırmaq imkanı"),
  createData("Yeni Şəbəkə Əlaqələri", "İstifadəçilər tərəfindən ikinci dərəcəli (Modem, GPRS, Bluetooth, Ethernet adapteri və s.) qoşulmaların real vaxt rejimində monitorinqi"),
  createData("Şəbəkə trafikinin dinlənməsi (sniffing)", "Şəbəkə trafikini dinləyən proqramların (hansı proqram olduğundan asılı olmayaraq) real vaxt rejimində aşkarlanması və xəbərdar edilməsi."),
  createData("Qeyri-domen maşınları", "Domen (domain) daxilinde olmayan cihazlarla girişlərin aşkarlanması"),
  createData("İcazəsiz DHCP Serverləri", "Təsadüfən (və ya qəsdən) istismara verilmiş DHCP serverlərinin aşkarlanması"),
  createData("ARP Spoofing", "ARP Spoofing edilən və edən cihaz haqqında xəbərdarlıq"),
  createData("Admin Shares-dən istifadə", "Sistem administratorları C$, ADMIN$ və s. olan istifadəçi kompüterlərinə daxil ola bilərlər. İstifadəçi ilə real vaxtda ediləcək əlaqələri izləmək və istifadəçini xəbərdar etmək imkanı")
];

const rowsForInventoryPolicyComplianceMngmnt = [
  createData(`Hardware Inventory
  (Aparat İnventarlaşdırması)
  `, `Kompüterlərin və qoşulmuş cihazların (ana plata, yaddaş, şəbəkə kartları, disk, qrafik kartı, veb-kamera və skaner və s.) ətraflı inventar məlumatlarının toplanması`),
  createData(`Software Inventory
  (Proqram təminatının inventarlaşdırılması)
  `, "Əməliyyat sistemi məlumatı, quraşdırılmış proqramlar və yamaqlar, quraşdırma əsas məlumatlarının toplanması, xidmətlər, reyestr parametrləri, istifadəçilər, qruplar və s."),
  createData(`Avadanlıq (hardware) dəyişikliyinin izlənməsi`, "Kritik sistem komponentlərindəki dəyişikliklərin (quraşdırılması, çıxarılması, dəyişdirilməsi) real vaxt rejimində izlənməsi"),
  createData("Reyestr dəyərlərinin monitorinqi", "Reyestr dəyərlərinin müəyyən bir dəyərdə olub-olmadığını yoxlamaq"),
  createData("Fayl / Kataloq uyğunluğu", "Müəyyən bir kataloqda olan / olmayan qovluqların və faylların izlənməsi")
]

export default function ColumnGroupingTable() {

  const [checkedArch, setArchChecked] = useState(false);
  const [rotationDegArch, setRotationArchDeg] = useState("0deg");

  const [checkedLog, setCheckedLog] = useState(false);
  const [rotationLog, setRotationLog] = useState("0deg");

  const [checkedSupported, setCheckedSupported] = useState(false);
  const [rotationSupported, setrotationSupported] = useState("0deg");

  const [checkedPreSet, setCheckedPreSet] = useState(false);
  const [rotationPreSet, setRotationPreSet] = useState("0deg");

  const [checkedInventory, setCheckedInventory] = useState(false);
  const [rotationInventory, setRotationInventory] = useState("0deg");

  const handleChangeArch = () => {
    if(checkedArch) {
      setRotationArchDeg("0deg")
    } else {
      setRotationArchDeg("-90deg")
    }
    setArchChecked((prev) => !prev);
  }

  const handleChangeLog = () => {
    if(checkedLog) {
      setRotationLog("0deg")
    } else {
      setRotationLog("-90deg")
    }
    setCheckedLog((prev) => !prev);
  }

  const handleChangeSupported = () => {
    if(checkedSupported) {
      setrotationSupported("0deg")
    } else {
      setrotationSupported("-90deg")
    }
    setCheckedSupported((prev) => !prev);
  }

  const handleChangePreSet = () => {
    if(checkedPreSet) {
      setRotationPreSet("0deg")
    } else {
      setRotationPreSet("-90deg")
    }
    setCheckedPreSet((prev) => !prev);
  }

  const handleChangeInventory = () => {
    if(checkedInventory) {
      setRotationInventory("0deg")
    } else {
      setRotationInventory("-90deg")
    }
    setCheckedInventory((prev) => !prev);
  }

  return (
    <>
      <Paper sx={{ width: '100%' }}>
        <TableContainer style={{borderRadius: "4px"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{display: "table-header-group", borderRadius: "4px"}}>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                  System Architecture (Sistem Memarlığı)
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  <Icon onClick={handleChangeArch} fontSize="large" style={{ color: "black", transform: `rotate(${rotationDegArch})`, cursor: "pointer"}}>keyboard_arrow_down_icon</Icon>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Collapse in={checkedArch}>
              {rowsForSystemArch
                .map((row) => 
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
                </Collapse>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper sx={{ width: '100%', marginTop: "50px" }}>
        <TableContainer style={{borderRadius: "4px"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{display: "table-header-group"}}>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                Log Collection Features (Log Toplama Xüsusiyyətləri)
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  <Icon onClick={handleChangeLog} fontSize="large" style={{ color: "black", transform: `rotate(${rotationLog})`, cursor: "pointer"}}>keyboard_arrow_down_icon</Icon>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Collapse in={checkedLog}>
                {rowsForLogCollection
                  .map((row) => 
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  )}
              </Collapse>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper sx={{ width: '100%', marginTop: "50px" }}>
        <TableContainer style={{borderRadius: "4px"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{display: "table-header-group"}}>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                Supported Log Sources (Dəstəklənən Log Mənbələri)
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  <Icon onClick={handleChangeSupported} fontSize="large" style={{ color: "black", transform: `rotate(${rotationSupported})`, cursor: "pointer"}}>keyboard_arrow_down_icon</Icon>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Collapse in={checkedSupported}>
              {rowsForSupportedLogSources
                .map((row) => 
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
              </Collapse>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper sx={{ width: '100%', marginTop: "50px" }}>
        <TableContainer style={{borderRadius: "4px"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{display: "table-header-group"}}>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                Pre-Set Alert Rules (Hazır Xəbərdarlıq Qaydaları)
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  <Icon onClick={handleChangePreSet} fontSize="large" style={{ color: "black", transform: `rotate(${rotationPreSet})`, cursor: "pointer"}}>keyboard_arrow_down_icon</Icon>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Collapse in={checkedPreSet}>
              {rowsForPresetAlertRules
                .map((row) => 
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
              </Collapse>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper sx={{ width: '100%', marginTop: "50px" }}>
        <TableContainer style={{borderRadius: "4px"}}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{display: "table-header-group"}}>
              <TableRow>
                <TableCell align="center" colSpan={1}>
                Inventory / Policy Compliance Management
                (İnventar / Siyasət Uyğunluğunun İdarə Edilməsi)
                </TableCell>
                <TableCell align="center" colSpan={1}>
                  <Icon onClick={handleChangeInventory} fontSize="large" style={{ color: "black", transform: `rotate(${rotationInventory})`, cursor: "pointer"}}>keyboard_arrow_down_icon</Icon>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Collapse in={checkedInventory}>
              {rowsForInventoryPolicyComplianceMngmnt
                .map((row) => 
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
                </Collapse>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
