/*
=========================================================
* KARMASIS - v2.0.1
=========================================================

* Product Page: https://material-ui.com/store/items/otis-kit-pro-material-kit-react/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// KARMASIS components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// KARMASIS examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleFooter from "examples/Footers/SimpleFooter";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "pages/Blogs/SingleArticle/ScreenRecorder/sections/FaqCollapse";

// Routes
import routes from "routes";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
      <MKBox component="section" pt={20} pb={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Card>
                <MKBox
                  variant="#271834"
                  bgColor="#271834"
                  borderRadius="lg"
                  coloredShadow="info"
                  p={3}
                  mt={-3}
                  mx={2}
                >
                  <MKTypography variant="h3" color="white">
                  TEZ-TEZ SORUŞULAN SUALLAR
                  </MKTypography>
                </MKBox>
                <MKBox p={6}>
                  <FaqCollapse
                    title="1 saatlıq seans nə qədər disk sahəsi tutur?"
                    open={collapse === 1}
                    onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                  >
                    Disk tələbi seansın nə qədər davam etdiyinə deyil, görülən işlərə görə dəyişir. Daxil olsanız və heç nə etməsəniz, heç bir disk sahəsi boşa getmir. Daxil olan şəxsin klikləri və klaviatura hərəkətləri nə qədər disk sahəsinin istehlak edildiyini müəyyənləşdirir. Bu rəqəm ekran həlli və yazıcı parametrindən asılı olaraq hər klik/düymə üçün 30K-100K arasında dəyişir.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Mənə hansı avadanlıq lazımdır?"
                    open={collapse === 2}
                    onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                  >
                    Giriş səviyyəli bir serverlə, 60-70 server asanlıqla qeydiyyatdan keçə bilər.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Virtual mühitdə quraşdıra bilərəmmi?"
                    open={collapse === 3}
                    onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                  >
                    Bəli.
                  </FaqCollapse>
                  <FaqCollapse
                    title="Qeydə alacağım müştərilərin əməliyyat sistemi vacibdirmi?"
                    open={collapse === 4}
                    onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
                  >
                    Windows Server 2008 və daha yüksək versiyalar server tərəfində, Windows Vista və daha yüksək versiyalar istifadəçi tərəfində dəstəklənir (Windows 7, 8, 8.x, 10)
                  </FaqCollapse>
                  <FaqCollapse
                    title="Yerli (local) sessiyaları qeyd edirmi?"
                    open={collapse === 5}
                    onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
                  >
                    Bəli.
                  </FaqCollapse>
                  <FaqCollapse
                    title="RDP və ya Citrix ICA üçün dəstək varmı?"
                    open={collapse === 6}
                    onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                  >
                    Bəli.
                  </FaqCollapse>
                  <FaqCollapse
                    title="VDI sistemləri ilə uyğun gəlirmi?"
                    open={collapse === 7}
                    onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                  >
                    Bəli.
                  </FaqCollapse>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
  );
}

export default Faq;
