// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// KARMASIS components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/karmasis-logo-yellow.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "KARMASIS",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
    },
    {
      icon: <GitHubIcon />,
      link: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://material-ui.com/store/items/otis-kit-pro-material-kit-react/",
    },
  ],
  menus: [
    {
      name: "Məhsul və Xidmətlər",
      items: [
        {
          name: "Infraskope SIEM++",
          href: "/product/infraskope-siem",
        },
        {
          name: "Dataskope DAM",
          href: "/product/dataskope-database",
        },
        {
          name: "Infraskope Screen Recorder",
          href: "/product/infraskope-screen-recorder",
        }
      ],
    },
    {
      name: "Infraskope SIEM++",
      items: [
        {
          name: "AppTracker",
          href: "/product/app-tracker",
        },
        {
          name: "Infraskope Agent",
          href: "/product/infraskope-agent",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} KARMASIS
    </MKTypography>
  ),
};
